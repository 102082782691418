import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";

const WeWorkWith = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto px-10 mb-24">
      <Slide left duration={1000}>
        <h2 className="text-4xl md:text-5xl w-max font-semibold text-white">{t("weWorkMainTitle")}</h2>
      </Slide>
      <div className="md:flex md:flex-wrap md:justify-between md:px-14">
        <div className="py-8 md:py-12 md:w-1/3">
          <div className="w-1/2 py-2 mx-auto">
            <StaticImage
              src="../images/global-presence.png"
              alt={t("weWorkWith1Title")}
            />
          </div>
          <div>
            <Slide bottom duration={1000}>
              <h3 className="text-2xl font-bold py-6 w-max mx-auto text-white">
                {t("weWorkWith1Title")}
              </h3>
            </Slide>
            <Slide bottom duration={1000}>
              <p className="text-lg md:px-5 text-center text-darkGray font-light">
                {t("weWorkWith1Description")}
              </p>
            </Slide>
          </div>
        </div>
        <div className="py-8 md:py-12 md:w-1/3">
          <div className="w-1/2 py-2 mx-auto">
            <StaticImage src="../images/custom-made.png" alt={t("weWorkWith2Title")} />
          </div>
          <div>
            <Slide bottom duration={1000}>
              <h3 className="text-2xl font-bold py-6 w-max mx-auto text-white">
                {t("weWorkWith2Title")}
              </h3>
            </Slide>
            <Slide bottom duration={1000}>
              <p className="text-lg md:px-5 text-center text-darkGray font-light">
                {t("weWorkWith2Description")}
              </p>
            </Slide>
          </div>
        </div>
        <div className="py-8 md:py-12 md:w-1/3">
          <div className="w-1/2 py-2 mx-auto">
            <StaticImage src="../images/get-support.png" alt={t("weWorkWith3Title")} />
          </div>
          <div>
            <Slide bottom duration={1000}>
              <h3 className="text-2xl font-bold py-6 w-max mx-auto text-white">
                {t("weWorkWith3Title")}
              </h3>
            </Slide>
            <Slide bottom duration={1000}>
              <p className="text-lg md:px-5 text-center text-darkGray font-light">
                {t("weWorkWith3Description")}
              </p>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeWorkWith
